<template>
  <section>
    <section class="top">
      <div>
        <p class="res_title">
          Result
        </p>
        <p class="res_subtitle">
          Create New Result
        </p>
      </div>
      <div>
        <p class="res_subtitle">
          {{ stdName ? stdName : 'Student Name' }}
        </p>
        <p class="res_subtitle">
          {{ stdClass ? `Class: ${stdClass}`: 'Student Class' }}
        </p>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          @click="confSave = true"
        >
          Save
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="res_main_grid">
        <VForm
          ref="formBasic"
          v-model="valid"
          lazy-validation
        >
          <VCard class="res_card shadow">
            <p class="res_main_title pb-3">
              Profile
            </p>
            <div class="res_content">
              <div class="span2">
                <VTextField
                  v-model="stdName"
                  :rules="[rules.required]"
                  :value="stdName"
                  :hide-details="true"
                  label="Name"
                  placeholder="Camila Cabello"
                  class="res_main_txt"
                />
              </div>
              <div>
                <VTextField
                  v-model="admNoInput"
                  :rules="[rules.required]"
                  :mask="admNoFormat"
                  :hide-details="true"
                  prefix="FLA"
                  placeholder="000-1920"
                  class="res_main_txt"
                  label="Admission Number"
                />
              </div>
              <div>
                <VSelect
                  v-model="term"
                  :rules="[rules.required]"
                  :items="selectTerm"
                  :hide-details="true"
                  class="res_main_txt"
                  label="Term"
                  placeholder="FA1/SA1"
                />
              </div>
              <div>
                <VSelect
                  v-model="stdClass"
                  :rules="[rules.required]"
                  :items="selectClass"
                  :hide-details="true"
                  class="res_main_txt"
                  label="Class"
                  placeholder="PG/5/5-A"
                />
              </div>
              <!-- Date Of Birth -->
              <div>
                <VDialog
                  ref="stdDobModal"
                  v-model="dobModal"
                  :return-value.sync="stdDobDef"
                  lazy
                  full-width
                  persistent
                  width="290"
                >
                  <VTextField
                    slot="activator"
                    :rules="[rules.required]"
                    :value="stdDob"
                    :hide-details="true"
                    label="Date Of Birth"
                    placeholder="01-12-1990"
                    class="res_main_txt"
                    readonly
                  />
                  <VCard class="elevation-20 picker_card">
                    <VDatePicker
                      ref="picker"
                      v-model="stdDobDef"
                      :max="new Date().toISOString().substr(0, 10)"
                      color="primary"
                      no-title
                      scrollable
                      class="elevation-0 pt-2"
                      min="1990-01-01"
                    />
                    <VCardActions>
                      <VSpacer />
                      <div class="pt-2 pr-2 pl-0 pb-2">
                        <VBtn
                          class="ma-0"
                          color="primary"
                          flat
                          round
                          depressed
                          @click="dobModal = false"
                        >
                          Close
                        </VBtn>
                        <VBtn
                          class="ma-0"
                          color="primary"
                          flat
                          round
                          depressed
                          @click="$refs.stdDobModal.save(stdDobDef)"
                        >
                          Save
                        </VBtn>
                      </div>
                    </VCardActions>
                  </VCard>
                </VDialog>
              </div>
              <!-- Date Of Birth END-->
              <div>
                <VTextField
                  v-model="session"
                  :rules="[rules.required]"
                  :mask="sessionFormat"
                  :hide-details="true"
                  class="res_main_txt"
                  placeholder="2019-2020"
                  label="Session"
                />
              </div>
              <div>
                <VSelect
                  v-model="classTeacher"
                  :rules="[rules.required]"
                  :items="selectTeacher"
                  :hide-details="true"
                  class="res_main_txt"
                  label="Class Teacher"
                  placeholder="Jyoti Gupta"
                />
              </div>
              <div class="span2">
                <VTextField
                  v-model="parentName"
                  :rules="[rules.required]"
                  :value="parentName"
                  :hide-details="true"
                  label="Parent"
                  placeholder="Alejandro Cabello"
                  class="res_main_txt"
                />
              </div>
            </div>
          </VCard>
        </VForm>

        <div>
          <div class="res_grid">
            <div
              v-for="(i, key) in marks"
              :key="key"
            >
              <VCard class="res_card shadow">
                <p class="res_main_txt res_percent">
                  {{ marks[key].percent = calcPercent(marks[key].obtained,marks[key].maximum) }} %
                </p>
                <div class="d-none">
                  {{ marks[key].color = getColor(marks[key].percent) }}
                </div>
                <p class="res_main_txt">
                  {{ i.subject }}
                </p>
                <p
                  v-if="marks[key].maximum === 100"
                  class="res_main_txt"
                >
                  Grade: {{ marks[key].grade =
                    calcGrade10(marks[key].obtained,marks[key].maximum) }}
                </p>
                <p
                  v-if="marks[key].maximum === 50"
                  class="res_main_txt"
                >
                  Grade: {{ marks[key].grade =
                    calcGrade5(marks[key].obtained,marks[key].maximum) }}
                </p>

                <div class="res_sub_grid">
                  <VTextField
                    v-model.number="marks[key].obtained"
                    :hide-details="true"
                    label="Obtained"
                    placeholder="30 / 80"
                    class="res_main_txt"
                    type="number"
                  />
                  <VTextField
                    v-model.number="marks[key].maximum"
                    :hide-details="true"
                    label="Maximum"
                    placeholder="50 / 100"
                    class="res_main_txt"
                    type="number"
                  />
                </div>
              </VCard>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div>
      <VDialog
        v-model="isLoading"
        persistent
        fullscreen
        transition="dialog-bottom-transition"
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_fs elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="white"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_fs elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_fs elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
      <VDialog
        v-model="isSaving"
        width="300"
        persistent
      >
        <VCard
          v-if="loadStatus === 'processing'"
          class="notification_card elevation-20"
        >
          <p>Processing</p>
          <span>Saving Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'success'"
          class="notification_card elevation-20"
        >
          <p>Saved</p>
          <span>Result Published For <strong>{{ stdName }}</strong></span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'savefailed'"
          class="notification_card elevation-20"
        >
          <p>Failed</p>
          <span>Failed To Publish Result For <strong>{{ stdName }}</strong></span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_card elevation-20"
        >
          <p>Internet Issue</p>
          <span>Please Try Again Later</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_card elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
      <VDialog
        v-model="confSave"
        width="300"
        persistent
      >
        <ConfirmAction
          @confirm="saveResult()"
          @close="confSave = false"
        />
      </VDialog>
      <VSnackbar
        v-model="snackbar"
        color="secondary"
        :timeout="2500"
        :multi-line="true"
      >
        {{ snackbarTxt }}
        <VBtn
          color="primary"
          depressed
          style="width:100px;"
          round
          @click="snackbar = false"
        >
          Close
        </VBtn>
      </VSnackbar>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import api from '../../conf/api';
import ConfirmAction from '../../components/confirmAction.vue';

export default {
  components: {
    ConfirmAction,
  },

  data: () => ({
    isLoading: true,
    isSaving: false,
    loadStatus: '',
    retry: 0,
    valid: true,
    confSave: false,
    snackbar: false,
    snackbarTxt: '',
    // basic info
    stdName: '',
    admNoInput: '',
    admNoFormat: '###-####',
    term: '',
    stdClass: '',
    dobModal: false,
    stdDobDef: null,
    // stdDob
    session: '',
    // sessionFormatted
    sessionFormat: '####-####',
    classTeacher: '',
    parentName: '',
    // get info
    selectTeacher: [],
    selectClass: [],
    selectSubject: [],
    selectTerm: [],
    // result info
    marks: [],
    rules: {
      email:
        (val) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(val) || 'Invalid e-mail.';
        },
      required: (val) => {
        if (val == null) {
          return 'Required';
        }
        if (val === '') {
          return 'Required';
        }
        return true;
      },
    },
  }),
  computed: {
    sessionFormatted() {
      const sessionFormatted = `${this.session.substring(0, 4)}-${this.session.substring(4)}`;
      return sessionFormatted;
    },
    stdDob() {
      if (this.stdDobDef !== null) {
        return moment(this.stdDobDef).format('DD-MM-YYYY');
      }
      return '';
    },
    admNo() {
      const admInput = this.admNoInput;
      const admNoFormatted = `FLA-${admInput.substring(0, 3)}-${admInput.substring(3)}`;
      return admNoFormatted;
    },
  },
  watch: {
    dobModal(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
        });
      }
    },

  },
  mounted() {
    // Set Session
    const currentYear = moment().format('YYYY');
    const nextYear = Number(currentYear) + 1;
    this.session = `${currentYear}${nextYear}`;
    // Set Session End
  },
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/settings/teachers`)
        .then((res) => {
          if (res.data !== '') {
            this.selectTeacher = res.data.data;
          }
        });
      await axios.get(`${api}/settings/classes`)
        .then((res) => {
          if (res.data !== '') {
            this.selectClass = res.data.data;
          }
        });
      await axios.get(`${api}/settings/subjects`)
        .then((res) => {
          if (res.data !== '') {
            this.selectSubject = res.data.data;
          }
        });
      await axios.get(`${api}/settings/terms`)
        .then((res) => {
          if (res.data !== '') {
            this.selectTerm = res.data.data;
          }
        });
      // Load Subjects and then push every subject with added fields
      this.selectSubject.forEach((sub) => {
        this.marks.push({
          subject: sub, obtained: '', maximum: '', percent: this.calcPercent, color: this.getColor, grade: '',
        });
      });
      this.isLoading = false;
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    getColor(percent) {
      if (percent >= 80) {
        return 'green';
      }
      if (percent >= 60) {
        return 'orange';
      }
      if (percent >= 0) {
        return 'red';
      }
      return 'primary';
    },
    calcPercent(obt, max) {
      if (obt && max !== '') {
        return Math.floor((obt / max) * 100);
      } return 0;
    },
    calcGrade10(obt, max) {
      // 100 Marks
      if (max === 100) {
        if (obt >= 91) {
          return 'A1';
        }
        if (obt >= 81 && obt <= 90) {
          return 'A2';
        }
        if (obt >= 71 && obt <= 80) {
          return 'B1';
        }
        if (obt >= 61 && obt <= 70) {
          return 'B2';
        }
        if (obt >= 51 && obt <= 60) {
          return 'C1';
        }
        if (obt >= 41 && obt <= 50) {
          return 'C2';
        }
        if (obt >= 33 && obt <= 40) {
          return 'D';
        }
        if (obt >= 21 && obt <= 32) {
          return 'E1';
        }
        if (obt >= 0 && obt <= 20) {
          return 'E2';
        }
      }
      return null;
    },
    calcGrade5(obt, max) {
      // 50 Marks
      if (max === 50) {
        if (obt >= 41) {
          return 'A';
        }
        if (obt >= 31 && obt <= 40) {
          return 'B';
        }
        if (obt >= 21 && obt <= 30) {
          return 'C';
        }
        if (obt >= 11 && obt <= 20) {
          return 'D';
        }
        if (obt >= 0 && obt <= 10) {
          return 'E';
        }
      } return null;
    },
    saveResult() {
      if (this.$refs.formBasic.validate()) {
        const resultSchema = {
          basic: {
            studentName: this.stdName,
            admNo: this.admNo,
            term: this.term,
            studentClass: this.stdClass,
            dateOfBirth: this.stdDob,
            session: this.sessionFormatted,
            classTeacher: this.classTeacher,
            parentName: this.parentName,
          },
          result: this.marks,
        };
        this.isSaving = true;
        this.loadStatus = 'processing';
        axios.post(`${api}/result/add`, resultSchema).then((res) => {
          if (res.data.status === 'success') {
            this.loadStatus = 'success';
            setTimeout(() => {
              this.isSaving = false;
              this.$router.push('/result');
            }, 2500);
          } else {
            this.loadStatus = 'savefailed';
            setTimeout(() => {
              this.isSaving = false;
            }, 2500);
          }
        }).catch((e) => {
          if (e.code === 'auth/network-request-failed') {
            this.loadStatus = 'netfail';
            setTimeout(() => {
              this.isSaving = false;
            }, 5000);
          } else {
            this.loadStatus = 'fail';
          }
        });
      } else {
        this.confSave = false;
        this.snackbar = true;
        this.snackbarTxt = 'Please Fill In All The Fields';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Result/new';
</style>
